import { DEFAULT_PAGE_SIZE } from '@hooks/paginationQuery/constants'
import { PaginationIcon } from './PaginationIcon'
import * as S from './TablePagination.styles'
import { jumpToFirst, jumpToLast, nextPage, previousPage } from './icons'
import { TablePaginationProps } from './interfaces'

export const TablePagination = ({
  testId = 'table-pagination-id',
  pagination,
  setPage,
  isMobileView,
  ...props
}: TablePaginationProps) => {
  if (!pagination) return <></>

  const currentPage = pagination?.current_page
  const totalPages = pagination?.total_pages
  const isFirstPage = currentPage === 1
  const isLastPage = currentPage === totalPages
  const cursor = (currentPage - 1) * DEFAULT_PAGE_SIZE
  const totalCount = pagination?.total_count

  const PaginationUI = () => (
    <S.PaginationContainer>
      <PaginationIcon isDisabled={isFirstPage} onClick={() => setPage(1)}>
        {jumpToFirst}
      </PaginationIcon>

      <PaginationIcon
        isDisabled={isFirstPage}
        onClick={() => setPage(Math.max(currentPage - 1, 1))}
      >
        {previousPage}
      </PaginationIcon>

      <S.PagesIndicator>
        <span>
          {cursor + 1}-{Math.min(cursor + DEFAULT_PAGE_SIZE, totalCount)} of{' '}
          {totalCount}
        </span>
      </S.PagesIndicator>

      <PaginationIcon
        isDisabled={isLastPage}
        onClick={() => setPage(Math.min(currentPage + 1, totalPages))}
      >
        {nextPage}
      </PaginationIcon>

      <PaginationIcon
        isDisabled={isLastPage}
        onClick={() => setPage(totalPages)}
      >
        {jumpToLast}
      </PaginationIcon>
    </S.PaginationContainer>
  )

  if (isMobileView)
    return (
      <S.MobileWrapper>
        <PaginationUI />
      </S.MobileWrapper>
    )

  return (
    <S.PaginationWrapper {...props} data-testid={testId}>
      <tr>
        <td colSpan={5}>
          <PaginationUI />
        </td>
      </tr>
    </S.PaginationWrapper>
  )
}
