import { AngleSmallDown, AngleSmallUp } from '@commons/Icons'
import { GreenChecker } from '../GreenChecker'
import * as S from './ContentSwitcher.styles'
import { Props } from './interfaces'

export const ContentSwitcher = ({
  testId = 'content-switcher-id',
  name = '',
  items = [],
  onChange,
  sortData,
  ...props
}: Props) => {
  const { sortField, sortOrder } = sortData

  return (
    <S.Fieldset data-testid={testId} {...props}>
      {items.map((item, index) => {
        return (
          <S.Container key={index + item.value}>
            {sortField === item.sortKey && sortOrder === 'desc' ? (
              <AngleSmallDown />
            ) : (
              <AngleSmallUp />
            )}

            <S.SwitchItem
              name={name}
              id={index}
              value={item.value}
              checked={!!item.checked}
              onClick={(e) => onChange(e.target.value)}
            ></S.SwitchItem>
            <S.Label htmlFor={index}>
              <p>{item.value}</p>
              <GreenChecker size={24} />
            </S.Label>
          </S.Container>
        )
      })}
    </S.Fieldset>
  )
}
