import styled from 'styled-components'
import { Fieldset as DefaultFieldset } from '../Fieldset'
import { BodyMB, BodyMR } from '../Text/Text.styles'

export const Fieldset = styled(DefaultFieldset)`
  display: flex;
  flex-direction: column;
  gap: 16px;
`

export const SwitchItem = styled.input.attrs({
  type: 'radio'
})<any>`
  display: none;
`

export const Container = styled.div`
  align-items: center;
  border-radius: 8px;
  cursor: pointer;
  display: flex;
  height: 50px;
  padding: 12px;
  width: 100%;
  gap: 12px;

  input:checked + label,
  & {
    background-color: ${({ theme }) => theme.colors.shades.gray300};

    p {
      ${BodyMB}
    }

    div {
      display: flex;
    }
  }

  border-radius: 8px;
`

export const Label = styled.label<any>`
  div {
    display: none;
  }

  align-items: center;
  display: flex;
  justify-content: space-between;
  width: 100%;

  ${BodyMR}
`
